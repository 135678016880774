import axios, { AxiosError } from "axios";
import qs from "qs";

export const isDevelopment = process.env.NODE_ENV === "development";

export const apiUrl = isDevelopment
  ? "http://localhost:8501"
  : "https://sharecarapi.scxiniu.com";

interface POST_Params {
  url: string;
  data: object;
  contentType?: string;
  checkLogin?: boolean;
}

const _dealLogin = (res: any) => {
  if (res && res.status) {
    if (res.status == 401 || res.status == 403) {
      //go to login page
      console.log("status", res.status);
      localStorage.removeItem("token");
      document.location.href = "/login";
    }
  }
};

export const GET = (param: POST_Params) => {
  const { url, data, checkLogin = true } = param;
  const token = localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`${apiUrl}${url}?${qs.stringify(data)}`, {
        headers: {
          // "content-type": contentType,
          Authorization: checkLogin ? token : "",
        },
      })
      .then((response) => {
        // console.log("GET res", response);
        _dealLogin(response);
        const { status = null } = response;
        if (status === 200) {
          return response.data;
        } else {
          resolve(null);
        }
      })
      .then((data) => {
        // console.log("data", data);
        const { Code, Message, Result } = data;
        if (Code === 1) {
          resolve(Result);
        } else {
          reject(new AxiosError(Message, "CODE:0"));
        }
      })
      .catch((err) => {
        // console.log("GET Err", err);
        _dealLogin(err.response);
        reject(err);
      });
  });
};

export const POST = (param: POST_Params) => {
  const {
    url,
    data,
    contentType = "application/x-www-form-urlencoded",
    checkLogin = true,
  } = param;
  const token = localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .post(`${apiUrl}${url}`, qs.stringify(data), {
        headers: {
          "content-type": contentType,
          Authorization: checkLogin ? token : "",
        },
      })
      .then((response) => {
        // console.log("POST res", response);
        _dealLogin(response);
        const { status = null } = response;
        if (status === 200) {
          return response.data;
        } else {
          resolve(null);
        }
      })
      .then((data) => {
        // console.log("data", data);
        const { Code, Message, Result } = data;
        if (Code === 1) {
          resolve(Result);
        } else {
          reject(new AxiosError(Message, "CODE:0"));
        }
      })
      .catch((err) => {
        // console.log("POST Err", err);
        _dealLogin(err.response);
        reject(err);
      });
  });
};

export const FormatDateTime = (date: string, fmt: string = "M/d h:m:s") => {
  var time = new Date(date);
  //author: meizz
  // if (fmt == undefined || fmt == null) fmt = "M/d h:m:s";
  var o: any = {
    "M+": time.getMonth() + 1, //月份
    "d+": time.getDate(), //日
    "h+": time.getHours(), //小时
    "m+": time.getMinutes(), //分
    "s+": time.getSeconds(), //秒
    "q+": Math.floor((time.getMonth() + 3) / 3), //季度
    S: time.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (time.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

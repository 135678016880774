import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Login';
import AppRouter from './AppRouter';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from "history";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { NotifyProvider } from './Components/useNotify';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const history = createBrowserHistory();
// global.myHistory = history;

const CustomRouter = (props: any) => {
  const [state, setState] = useState({
    action: props.history.action,
    location: props.history.location
  });
  useLayoutEffect(() => history.listen(setState), [history]);
  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <NotifyProvider>
        <CustomRouter history={history}>
          <AppRouter />
        </CustomRouter>
      </NotifyProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

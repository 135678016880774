import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './Login.css';
import { useQuery, useQueryClient } from 'react-query';
import { POST, apiUrl } from './Global';

function Login() {
  document.title = '登录'
  // const [data, setData] = useState<any>(null);
  useEffect(() => {
    var token = localStorage.getItem('token');
    if (token != null) document.location.href = '/';

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  // User has switched back to the tab
  const onFocus = () => {
    // console.log("Tab is focus");
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    // console.log("Tab is blur");
  };

  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, refetch, isError, error } = useQuery(['erweima'], () =>
    POST({ url: '/User/BigScreenLoginMa', data: {} })
  );

  if (isError)
    return (
      <div className='fixed right-0 left-0 top-0 bottom-0 flex flex-col justify-center items-center space-y-3'>
        <div> Err! {`${error}`}
        </div>
      </div>
    )

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://property.cdxtx.net/static/Logo2.png"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          停车场监控屏幕
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        {/* <form className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              账号
            </label>
            <div className="mt-2">
              <input
                autoFocus={true}
                id="account"
                name="account"
                type="text"
                // autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                密码
              </label>
              <div className="text-sm">
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5  px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              登 录
            </button>
          </div>
        </form> */}
        <div className='flex flex-col items-center justify-center'>
          <div className='w-60 h-60 flex justify-center items-center bg-gray-10'>
            {isError ? <div >get data Err!</div> :
              <>
                {(isLoading || isFetching) ?
                  <div >loading</div>
                  :
                  <>
                    <img className="w-60 h-60" alt='loading' src={(data as any).base64} />
                    <Timer data={data} />
                  </>
                }
              </>
            }
          </div>
          <div className='mt-3'>小程序扫码登录</div>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          <br />Copyright 2022 成都星通星 All Rights Reserved
          {/* <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Start a 14 day free trial
          </a> */}
        </p>
      </div>
    </div>
  );
}

const Timer = ({ data }: any) => {
  useEffect(() => {
    const timer = setInterval(() => {
      TryGetToken();
    }, 2000);
    return () => clearInterval(timer);
  }, []);

  const TryGetToken = () => {
    // console.log('tryGetToken', data)
    if (data != null && (data as any).sign != null) {
      var sign = (data as any).sign;
      POST({ url: '/User/BigScreenGetToken', data: { sign } })
        .then((data) => {
          console.log('tryGetToken back', data)
          if ((data as string).indexOf("Bearer") === 0) {
            localStorage.setItem('token', data as string);
            document.location.href = '/';
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  return null
}

export default Login;

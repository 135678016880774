
import React, { useState, createContext, useContext, useEffect } from "react";

interface INotifyShow {
  message: string,
  autoHideDuration?: number
  type?: 'info' | 'error' | 'success';
}
interface INotifyParams {
  show: (params: INotifyShow) => void;
}

const NotifyContext = createContext<INotifyParams>({ show: (params: INotifyShow) => { } });

const NotifyProvider = (props: any) => {
  let timer: any;
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<INotifyShow | null>(null);
  const show = (params: INotifyShow) => {
    try { clearTimeout(timer); } catch { }

    const defaultParams: INotifyShow = {
      message: '',
      autoHideDuration: 3000,
      type: 'info',
    };
    const _params = { ...defaultParams, ...params };
    console.log('params', _params)
    setParams(_params)

    if (!open) setOpen(true);
    timer = setTimeout(() => { setOpen(false) }, _params.autoHideDuration);
  }
  const bgColor = (type?: string) => {
    switch (type) {
      case "error": return 'bg-red-800';
      case "success": return 'bg-green-800';
    }
    return 'bg-gray-800'
  }
  return (
    <NotifyContext.Provider value={{ show }} {...props}>
      {props.children}

      {open ?
        <div className={`animate-notify-animate z-50 fixed w-full py-3 text-white left-0 bottom-0 right-0 ${bgColor(params?.type)}`}>
          <div className="flex justify-center items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
            <span>
              {params?.message}
            </span>
          </div>
        </div>
        : null
      }
    </NotifyContext.Provider>
  );
};

const useNotify = () => {
  return useContext(NotifyContext);
};
export { NotifyContext, NotifyProvider, useNotify };
import React, { useState, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Login from './Login';
import App from './App';

function AppRouter() {
  return useRoutes([
    { path: '/', element: <App /> },
    { path: '/login', element: <Login /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export default AppRouter;